import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwaInstallComponent } from './pwa-install-popup.component';
import { PwaUpdateComponent } from './pwa-update-popup.component';
import { ButtonComponent } from '@agdir/ui/button';
import { TranslocoPipe } from '@ngneat/transloco';

@NgModule({
	declarations: [PwaInstallComponent, PwaUpdateComponent],
	exports: [PwaInstallComponent, PwaUpdateComponent],
	imports: [CommonModule, ButtonComponent, NgOptimizedImage, TranslocoPipe],
})
export class PwaModule {}

import { AgdirIconComponent } from '@agdir/agdir-icons';
import { ResponsiveService } from '@agdir/core/angular';
import { ButtonComponent } from '@agdir/ui/button';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AgdirBottomSheetComponent } from './bottom-sheet/bottom-sheet.component';

@Component({
	standalone: true,
	selector: 'agdir-modal',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [AgdirBottomSheetComponent, NzModalModule, NgTemplateOutlet, NgClass, AgdirIconComponent, ButtonComponent, TranslocoDirective],
	template: `
		<ng-template #content>
			<ng-content></ng-content>
		</ng-template>
		<nz-modal
			nzWrapClassName="agdir-drawer"
			[nzVisible]="!isMobile() ? isVisible() : false"
			[nzClosable]="false"
			nzCentered
			[nzFooter]="null"
			(nzOnCancel)="isVisible.set(false)"
		>
			<ng-container *nzModalContent>
				<div class="flex items-center justify-between pb-2 border-b border-b-gray-300">
					<h3 class="font-bold figma-mobile-header" [transloco]="title()"></h3>
					@if (closeButton()) {
						<agdir-button color="ghost" icon="close" (click)="close()" />
					}
				</div>
				<h4 class="figma-body-text-small-regular pb-1.5 border-b border-gray-300" [transloco]="subtitle()"></h4>
				<div class="flex-col-start gap-4 pt-4 w-full">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</div>
			</ng-container>
		</nz-modal>
		<agdir-bottom-sheet [isVisible]="isMobile() ? isVisible() : false" (close)="close()" [title]="title()">
			<div class="w-full">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</div>
		</agdir-bottom-sheet>
	`,
	styles: `
		::ng-deep .ant-drawer-mask {
			opacity: 0.5 !important;
			background: #d9d9d9 !important;
		}

		::ng-deep .agdir-drawer {
			@apply rounded-t-[10px];

			& > div {
				@apply rounded-t-[10px];
			}
		}

		h3:empty,
		h4:empty {
			display: none;
		}
	`,
})
export class AgdirModalComponent {
	isMobile = inject(ResponsiveService).isMobile();

	closeButton = input<boolean>(false);
	title = input<string>('');
	subtitle = input<string>('');
	isVisible = model<boolean>(false);

	close() {
		this.isVisible.set(false);
	}

	toggle() {
		this.isVisible.set(!this.isVisible());
	}
}

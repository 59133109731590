import { Directive, ElementRef, HostListener, inject, input, OnInit, Renderer2 } from '@angular/core';

type AgdirContainerQuery = {
	mobile1col?: string;
	mobile2col?: string;
	mobile3col?: string;
	mobile4col?: string;
	desktop1col?: string;
	desktop2col?: string;
	desktop3col?: string;
	desktop4col?: string;
	desktop5col?: string;
	desktop6col?: string;
	desktop7col?: string;
	desktop8col?: string;
	desktop9col?: string;
	desktop10col?: string;
	desktop11col?: string;
	desktop12col?: string;
	desktop13col?: string;
	desktop14col?: string;
	desktop15col?: string;
	desktop16col?: string;
	desktop17col?: string;
	desktop18col?: string;
};

const columnWidths: { [key: string]: number } = {
	mobile1col: 64,
	mobile2col: 136,
	mobile3col: 208,
	mobile4col: 280,
	desktop1col: 64,
	desktop2col: 152,
	desktop3col: 240,
	desktop4col: 328,
	desktop5col: 416,
	desktop6col: 504,
	desktop7col: 592,
	desktop8col: 680,
	desktop9col: 768,
	desktop10col: 856,
	desktop11col: 944,
	desktop12col: 1032,
	desktop13col: 1120,
	desktop14col: 1208,
	desktop15col: 1296,
	desktop16col: 1384,
	desktop17col: 1472,
	desktop18col: 1560,
};

@Directive({
	standalone: true,
	selector: '[agdirContainerQuery]',
})
export class AgdirContainerQueryDirective implements OnInit {
	agdirContainerQuery = input.required<AgdirContainerQuery>({});
	// we dont know which is going to be used more so we require the user to specify it. later we can set a default and searchreplace all the usages
	queryMatch = input.required<'exact' | 'min'>();
	private currentClassList: string[] = [];

	private el = inject(ElementRef);
	private renderer = inject(Renderer2);

	@HostListener('window:resize', ['$event'])
	ngOnInit() {
		// &shrug; ng-content is not available in ngOnInit
		setTimeout(() => {
			this.applyClassBasedOnWidth();
		});
	}

	private applyClassBasedOnWidth() {
		const el = this.el.nativeElement;
		const parentWidth = el.parentElement.getBoundingClientRect().width;
		const allKeys = Object.keys(this.agdirContainerQuery());
		const matchingKeys = this.getMatchingKeys(parentWidth, allKeys);
		Object.values(this.agdirContainerQuery()).forEach((classes) =>
			classes
				.split(' ')
				.map((c) => c.trim())
				.filter((c) => !!c)
				.forEach((c) => this.renderer.removeClass(el, c)),
		);
		matchingKeys
			.map((c) => c.trim() as keyof AgdirContainerQuery)
			.filter((c) => !!c)
			.forEach((key) => this.upsertClasses(key));
	}

	private getMatchingKeys(parentWidth: number, allKeys: string[]): Array<keyof AgdirContainerQuery> {
		return Object.entries(columnWidths)
			.filter(([key, width]) => (this.queryMatch() === 'exact' ? width === parentWidth : width >= parentWidth))
			.map(([key]) => key as keyof AgdirContainerQuery);
	}

	private upsertClasses(matchingKey: keyof AgdirContainerQuery) {
		const el = this.el.nativeElement;
		const matchingClass = (matchingKey && this.agdirContainerQuery()[matchingKey]) || '';
		matchingClass
			.split(' ')
			.map((c) => c.trim())
			.filter((c) => !!c)
			.forEach((c) => this.renderer.addClass(el, c));
	}
}

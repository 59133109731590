import { ChangeDetectionStrategy, Component, HostBinding, signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-offline-detect',
	template: `
		{{ 'pwa.offline.appIsOfflineMessage' | transloco }}
	`,
	imports: [TranslocoPipe],
})
export class OfflineDetectComponent {
	@HostBinding('class.hidden')
	isOnline = signal(true);

	constructor() {
		this.updateOnlineStatus();
		window.addEventListener('online', this.updateOnlineStatus.bind(this));
		window.addEventListener('offline', this.updateOnlineStatus.bind(this));
	}

	private updateOnlineStatus(): void {
		this.isOnline.set(window.navigator.onLine);
	}
}

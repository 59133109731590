import { AgdirSelectOption } from '@agdir/agdir-forms';
import { Component, input, model, ViewEncapsulation } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
	standalone: true,
	selector: 'agdir-tab-toggle',
	template: `
		@for (t of tabs(); track $index) {
			<div class="tab" [class.active]="tab() === t" (click)="tab.set(t)">
				{{ t.label | transloco }}
			</div>
		}
	`,
	styles: [
		`
			agdir-tab-toggle {
				background-color: var(--tab-toggle-bg);
				color: var(--tab-toggle-text);
				font-size: 16px;
				@apply flex-row-center gap-[3px] p-[3px] rounded-[10px];

				.tab {
					@apply transition-colors duration-200;
					@apply cursor-pointer rounded-[5px] py-0.5 px-1 text-center font-bold;

					&.active {
						background-color: var(--tab-toggle-active-bg);
						color: var(--tab-toggle-active-text);
					}
				}
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
	imports: [TranslocoPipe],
})
export class TabToggleComponent {
	tabs = input<AgdirSelectOption[]>([]);
	tab = model<AgdirSelectOption>();
}

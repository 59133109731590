import { AgdirButtonColor, ButtonComponent, ButtonSizeType } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'agdir-header-button',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		@if (isMenuItem()) {
			<agdir-button color="ghost" size="default" class="w-full" [icon]="icon()" [label]="label()" />
		} @else {
			<agdir-button [color]="color()" [label]="label()" [icon]="icon()" [size]="size()" />
		}
	`,
	imports: [ButtonComponent],
})
export class HeaderButtonComponent {
	isMenuItem = input<boolean>(false);

	label = input<string>('');
	icon = input<string>('');
	color = input<AgdirButtonColor>('secondary');
	size = input<ButtonSizeType>('default');
}

import { VendorName } from './vendor-name';
import { Location } from '@agdir/domain';
import { EquipmentCategory } from './equipment-category';
import { Classifier } from '@agdir/classifiers';

export class Equipment {
	id: number = -1;
	companyId: string | null;
	name: string | null;
	category?: EquipmentCategory | null;
	make?: string | null;
	model?: string | null;
	lastSeen?: string | Date | null;
	deletedAt?: string | Date | null;
	variant?: string | null;
	color?: string | null;
	serialNumber?: string | null;
	equipmentNumber?: string | null;
	engineHours?: { from: number; to: number }[];
	hoursOfOperation?: { from: number; to: number; location: Location }[];
	vendorStatus?: any;

	vendor: VendorName;
	isOnline?: boolean;
	manufacturer?: Classifier;
	type?: Classifier;

	static fromClassifier(classifier: Partial<Classifier>) {
		const equipment = new Equipment();
		equipment.id = +classifier.id;
		equipment.name = classifier.name;
		return equipment;
	}
}
